<template>
    <div>
        <div class="filter-container">
            <div class="left-content">
                <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                    <template #button-content>
                        <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i class="icon icon-keyboard"></i></span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>Параметры фильтра</span>
                            <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                        </div>
                        <div class="filter-block">
                           <b-form-group label="Плановый период">
                                <multiselect ref="ifPlanPeriod"
                                    v-model="curPeriod"
                                    track-by="name"
                                    label="name"
                                    @input="filterVariants(false)"
                                    placeholder="Выбрать период"
                                    :options="periodLst"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                >
                                </multiselect>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Область/район">
                                <multiselect ref="ifRegionBudget"
                                v-model="regObj"
                                track-by="text"
                                label="text"
                                placeholder="Выбрать область/район"
                                :options="listReg"
                                :searchable="true"
                                :allow-empty="false"
                                @input="filterVariants(false)"
                                :show-labels="false">
                            </multiselect>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Версия бюджета">
                                <multiselect ref="ifVariant"
                                    v-model="variantObj"
                                    track-by="text"
                                    label="text"
                                    placeholder="Выбрать версию бюджета"
                                    :options="listOfVariants"
                                    :searchable="true"
                                    :allow-empty="false"
                                    @input="updateDatas"
                                    :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                    </div>
                </b-dropdown>
            </div>
            <div class="filter-actions" v-if="mode !== 'report'">
                <b-button v-if="(variantObj!=null && (variantObjDataType === 3 || variantObjDataType === 4))" variant="success" @click="openCorrect">Причины</b-button>
                <b-button :disabled="dsblSaveBtn" variant="success" @click="saveDatas">Сохранить</b-button>
            </div>
        </div>

        <c-breadcrumbs
            :bCrumbs="[
                {obj: curPeriod, field: 'name', bcRef: 'ifPlanPeriod'},
                {obj: regObj, field: 'text', bcRef: 'ifRegionBudget'},
                {obj: variantObj, field: 'text', bcRef: 'ifVariant'},
            ]"
            @openFilterByRef="openFilterByRef">
        </c-breadcrumbs>
        <!-- <b-form-input   
                        id="filter-input1"
                        v-if="variantObjDataType"
                        v-model="variantObj.data_type"
                        type="search"
                        class="mini"
                    ></b-form-input> -->
        <b-progress variant="primary" v-show="bar<100" height="3px" :value="bar" striped animated></b-progress>
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                :tbody-tr-class="rowClass"
                :filter="filter"
                :filter-function="filterFunction"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
                >
                <template #head(action)="scope">
                    <b-button @click="openAll()">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="open"></i>
                        <i class="icon icon-chevron-circle" v-if="!open"></i>
                    </b-button>
                </template>
                <template #head(kat)="scope">
                    <div>КАТ</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.kat"
                        type="search"
                        class="mini"
                    ></b-form-input>
                </template>
                <template #head(cls)="scope">
                    <div>КЛ</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.cls"
                        type="search"
                        class="mini"
                    ></b-form-input>
                </template>
                <template #head(pcl)="scope">
                    <div>ПКЛ</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.pcl"
                        type="search"
                        class="mini"
                    ></b-form-input>
                </template>
                <template #head(spf)="scope">
                    <div>СП</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.spf"
                        type="search"
                        class="mini"
                    ></b-form-input>
                </template>
                <template #head(name_ru)="scope">
                    <div>Наименование</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.name_ru"
                        type="search"
                    ></b-form-input>
                </template>
                <template #head(receipt_prev)>
                    <div>{{ 'Поступления за ' + (parseInt(repYear) - 1) + 'год, тыс.тенге' }}</div>
                </template>
                <template #head(revised_plan)>
                    <div>{{ 'Уточненный план ' + (repYear) + ' года' + (revisedPlanMaxDat ? ' на ' + revisedPlanMaxDat + 'г.': '')}}</div>
                </template>
                <template #head(plan1)>
                    <div>{{ 'Прогноз на ' + (parseInt(repYear) + 1) + ' год, тыс.тенге' }}</div>
                </template>
                <template #head(plan2)>
                    <div>{{ 'Прогноз на ' + (parseInt(repYear) + 2) + ' год, тыс.тенге' }}</div>
                </template>
                <template #head(plan3)>
                    <div>{{ 'Прогноз на ' + (parseInt(repYear) + 3) + ' год, тыс.тенге' }}</div>
                </template>

                <template #cell(action)="data">
                    <b-button v-if="data.item.type === 1" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(kat)="data">
                    <div v-if="data.item.type === 1 || filtered === true" v-bind:class="{filtered : filtered === true && data.item.clss !== null}">{{ data.value }}</div>
                    <b-button v-if="data.item.type === 2" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(cls)="data">
                    <div v-if="data.item.type === 2 || (filtered === true && data.item.clss !== null)" v-bind:class="{filtered : filtered === true && data.item.pcl !== null}">{{ data.value }}</div>
                    <b-button v-if="data.item.type === 3" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(pcl)="data">
                    <div v-if="data.item.type === 3 || filtered === true" v-bind:class="{filtered : filtered === true && data.item.pcl !== null}">{{ data.value }}</div>
                </template>
                <template #cell(spf)="data">
                    <div v-if="data.item.type === 5">{{ data.value }}</div>
                </template>
                <template #cell(name_ru)="data">
                    <div>{{ data.value }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-right">
                        {{ toNum(data.value) === 0 ? null : $n(data.value) }}
                    </div>
                </template>
                <template #cell(plan1)="data">
                    <template v-if="checkIfCanBeEdit(data.item)">
                        <b-form-input class="text-right"
                                      :disabled = "isApproved"
                                      :value="$n(data.item.plan1)"
                                      @change="v => data.item.plan1 = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, numbersRegExpression)"
                                      @blur="inputFixed(data.item, 'plan1', data.item.plan1, numberOfDecimals)"
                        ></b-form-input>
                    </template>
                    <template v-else>
                        <div class="text-right">
                            {{ toNum(data.value) === 0 ? null : $n(data.value, numberOfDecimals) }}
                        </div>
                    </template>
                </template>
                <template #cell(plan2)="data">
                    <template v-if="(checkIfCanBeEdit(data.item) && variantObjDataType < 4)">
                        <b-form-input class="text-right"
                                      :disabled = "isApproved"
                                      :value="$n(data.item.plan2)"
                                      @change="v => data.item.plan2 = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, numbersRegExpression)"
                                      @blur="inputFixed(data.item, 'plan2', data.item.plan2, numberOfDecimals)"
                        ></b-form-input>
                    </template>
                    <template v-else>
                        <div class="text-right">
                            {{ toNum(data.value) === 0 ? null : $n(data.value, numberOfDecimals) }}
                        </div>
                    </template>
                </template>
                <template #cell(plan3)="data">
                    <template v-if="(checkIfCanBeEdit(data.item) && variantObjDataType < 4)">
                        <b-form-input class="text-right"
                                      :disabled = "isApproved"
                                      :value="$n(data.item.plan3)"
                                      @change="v => data.item.plan3 = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, numbersRegExpression)"
                                      @blur="inputFixed(data.item, 'plan3', data.item.plan3, numberOfDecimals)"
                        ></b-form-input>
                    </template>
                    <template v-else>
                        <div class="text-right">
                            {{ toNum(data.value) === 0 ? null : $n(data.value, numberOfDecimals) }}
                        </div>
                    </template>
                </template>
                <template #cell(note)="data">
                    <textarea v-if="(checkIfCanBeEdit(data.item))"
                              :disabled = "isApproved"
                              style="height: 100%"
                              cols="50"
                              v-model="data.item.note"
                              @keyup.enter.exact="keyup13"
                              @focusout="editing = true">
                    </textarea>
                </template>
                <template #bottom-row="data">
                    <td></td>
                    <td class="text-left" colspan="5">ИТОГО</td>
                    <td class="text-right">{{ $n(total('receipt_prev')) }}</td>
                    <td class="text-right">{{ $n(total('revised_plan')) }}</td>
                    <td class="text-right">{{ $n(total('receipt_curr')) }}</td>
                    <td>{{ total('revised_plan') > 0 ?(total('receipt_curr') / total('revised_plan') * 100).toFixed(2) : 0 }}</td>
                    <td class="text-right">{{ $n(total('plan1', numberOfDecimals)) }}</td>
                    <td class="text-right">{{ $n(total('plan2', numberOfDecimals)) }}</td>
                    <td class="text-right">{{ $n(total('plan3', numberOfDecimals)) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from '@/services/store';
import { variantForLimitIncome, variantNameLangSupport } from '@/modules/budget/budgetCorrectHelper';
import CBreadcrumbs from './components/c-breadcrumbs.vue';
import {CUR_YEAR} from "@/modules/budget-request/components/js/budgetCurYear";
import Decimal from 'decimal.js';

export default {
    name: 'IncomeForm',
    components: {CBreadcrumbs},
    props: {
        // year: Number,
        obl: String,
        reg: String,
        listReg: null,
        mode: String,
        listVariantsInc: Array,
        dataType: Number || null,
        accessLevel: { type: Number, required: false,  default: 0 }
    },
    computed: {
        isVariantActive() {
            return this.variantObj.attribute;
        },
        variantObjDataType() {
            if (this.variantObj && 'data_type' in this.variantObj) return  this.variantObj.data_type;
            return null;
        },
        isPlanShoudBeWholeNumber() {
            return ![3, 4].includes(this.variantObjDataType);
        },
        numbersRegExpression() {
            if (this.isPlanShoudBeWholeNumber) {
                return '^\\d+$';
            }
            return '^\\d*\\,?\\.?$';
        },
        numberOfDecimals() {
            if (this.isPlanShoudBeWholeNumber) {
                return 0;
            }
            return 1;
        }

    },
    data() {
        return {
            tableFields: [
                {
                    key: 'action',
                    label: ' ',
                     class: 'toggle-show'
                },
                {
                    key: 'kat',
                    label: 'КАТ',
                     class: 'toggle-show'
                },
                {
                    key: 'cls',
                    label: 'КЛ',
                     class: 'toggle-show'
                },
                {
                    key: 'pcl',
                    label: 'ПКЛ'
                },
                {
                    key: 'spf',
                    label: 'СП'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'receipt_prev',
                    label: ''
                },
                {
                    key: 'revised_plan',
                    label: ''
                },
                {
                    key: 'receipt_curr',
                    label: 'Поступления текущего года, тыс.тенге'
                },
                {
                    key: 'percent',
                    label: '% исполнения',
                    variant: 'danger'
                },
                {
                    key: 'plan1',
                    label: ''
                },
                {
                    key: 'change',
                    label: 'Изменение',
                    variant: 'danger',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'plan2',
                    label: ''
                },
                {
                    key: 'plan3',
                    label: ''
                },
                {
                    key: 'note',
                    label: 'Примечание',
                    variant: 'danger'
                },
                {
                    key: 'filter',
                    label: 'filter',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                }
            ],
            calcFlds: [
                'receipt_prev',
                'revised_plan',
                'receipt_curr',
                'plan1',
                'plan2',
                'plan3'
            ],

            filter: {
                kat: null,
                cls: null,
                pcl: null,
                spf: null,
                name_ru: null
            },

            dictTree: null,
            dictEbkDoh: {
                dict: [],
                maps: {}
            },
            budgetForm: [],
            spfMap: new Map(),
            valMap: new Map(),
            open: true,
            editing: false,
            bar: 0,
            repYear: 0,
            region: null,
            regObj: null,
            curPeriod: {},
            periodLst: [],
            filtered: false,
            listOfVariants: [],
            variant: '',
            variantObj: null,
            isApproved: true,
            dsblSaveBtn: false,
            revisedPlanMaxDat: null,
            year: new Date().getFullYear() - 1
        };
    },

    created() {
        const date = new Date();
        this.curYear = date.getFullYear();
        if ([1].includes(this.dataType)) {
            this.year = date.getFullYear() + 1;
        }
        else {
            this.year = date.getFullYear();
        }
        this.$watch('year', () => {this.repYear = this.year; this.chgData();});
        this.$watch('reg', () => { if (this && this.reg) this.region = this.reg; this.filterVariants();  });
        this.$watch('listReg', () => this.filterVariants(true))
        this.$watch('regObj', () => this.chgData())
        this.$watch('variantObj', () => this.chgData())
        this.$watch('curPeriod', function () {
            if (this.curPeriod.year) {
                this.repYear = this.curPeriod.year;
            }
            this.chgData();
        });
        this.$watch('listVariantsInc', () => this.filterVariants(true), {deep: true});
    },

    async mounted() {
        this.getPeriodLst();
        this.filterVariants(true);
    },

    beforeUpdate() {
        this.budgetForm.forEach(row => {
            if ([0, 1, 2, 3].includes(row.type)) {
                row._rowVariant = 'info';
            }
        });
    },

    methods: {
        async filterVariants(variantsChanged = false) {
            if (!this.regObj && this.listReg.length) {
                this.listReg.map(item => {
                    item.text = `${item.code} - ${item.name_ru}`
                    if (this.$i18n.locale === 'kk') item.text = `${item.code} - ${item.name_kk}`
                });
                this.regObj = this.listReg[0];
            }
            if (this.listVariantsInc.length === 0 || !this.regObj) { return; }
            this.region = this.regObj.code;
            this.listOfVariants = this.mode === "report" ? variantForLimitIncome(this.listVariantsInc, this.curPeriod.year + 1, this.region, this.dataType) : variantForLimitIncome(this.listVariantsInc, this.curPeriod.year + 1, this.region);
            
            this.listOfVariants.map(item => item.text = this.getVariantName(item))
            this.variantObj = this.listOfVariants[0] ? this.listOfVariants[0] : null
            if (this.listOfVariants.length > 0) {
                this.variant = this.listOfVariants[0].variant_uuid;
                this.isApproved =  this.listOfVariants[0].status;
                if (variantsChanged) {
                    await this.loadDictTree();
                    this.bar = 70;
                    await this.prepareForm();
                    this.bar = 100;
                    await this.rememberDatas();
                    this.chgData();
                } else {
                    this.updateDatas();
                }
            } else {
                this.makeToast('warning', 'Доходы --> Версия бюджета', 'Нет доступных версий бюджета');
                this.budgetForm = []
            }
        },

        getVariantName(variant) {
            return variantNameLangSupport(variant, this.$i18n.locale);
        },

        clearDatas() {
            for (const spf of this.spfMap.values()) {
                this.$set(spf, 'note', '');
                this.calcFlds.forEach((field) => {
                    this.$set(spf, field, 0);
                });
            }
        }, // очищает данные таблицы

        createTable(elem, parent_id) {
            const that = this;
            // создание таблицы на основе дерева
            const item = Object.assign({}, elem);

            this.$set(item, 'parent_id', parent_id);
            this.$set(item, 'visible', true);

            Object.defineProperty(item, 'parent', {
                get: function () {
                    for (const row of that.budgetForm) {
                        if (item.parent_id === row.id) {
                            return row;
                        }
                    }
                    return null;
                }
            });

            if (item.type === 5) {
                this.calcFlds.forEach((field) => {
                    if (item.hasOwnProperty(field)) {
                        this.$set(item, field, parseFloat(item[field]));
                    } else {
                        this.$set(item, field, 0);
                    }
                });

                this.spfMap.set(this.getRowKey(item, ['kat', 'cls', 'pcl', 'spf']), item);
            } else {
                this.$set(item, 'open', true);
                this.$set(item, 'hasChild', true);

                this.calcFlds.forEach(field => {
                    Object.defineProperty(item, field, {
                        get: function () {
                            return that.reSum(item, field);
                        }
                    });
                });
            }

            if (item.visual) {
                this.$set(item, 'index', this.budgetForm.length);
                this.$set(this.budgetForm, this.budgetForm.length, item);
            }
            Object.defineProperty(item, 'percent', {
                get: function () {
                    const rate = (that.toNum(item.revised_plan) === 0 ? 0
                        : (that.toNum(item.receipt_curr) / that.toNum(item.revised_plan)) * 100);
                    return that.toNum(rate, 2);
                }
            });

            if (item.hasChild) {
                for (const ch of item.child) {
                    this.createTable(ch.value, item.id);
                }
                delete item.child;
            }
        }, // древовидную выборку преобразовывает в таблицу (для отображения)

        compareDatas() {
            // выясняем есть ли введенные значения для сохранения
            const user = store.state.user.login;
            const saveDatas = [];
            for (const row of this.spfMap.values()) {
                const val = this.valMap.get(this.getRowKey(row, ['kat', 'cls', 'pcl', 'spf']))
                                    .replace('"visible":true', '"visible":false');
                const newRow = {...row, visible: false}
                if (JSON.stringify(newRow) !== val) {

                    saveDatas.push(this.newData(
                        row.kat, row.cls, row.pcl, row.spf,
                        parseFloat(row.plan1), row.note, parseInt(this.repYear) + 1,
                        this.region, this.variant, user, this.repYear + 1));

                    saveDatas.push(this.newData(
                        row.kat, row.cls, row.pcl, row.spf,
                        parseFloat(row.plan2), row.note, parseInt(this.repYear) + 2,
                        this.region, this.variant, user, this.repYear + 1));

                    saveDatas.push(this.newData(
                        row.kat, row.cls, row.pcl, row.spf,
                        parseFloat(row.plan3), row.note, parseInt(this.repYear) + 3,
                        this.region, this.variant, user, this.repYear + 1));
                }
            }
            return saveDatas;
        }, // сравнивает введенные данные с ранее сохраненные и формирует массив новых записей для сохранения в БД

        focusout() {
            if (!this.editing) {
                return;
            }
            const res = this.compareDatas();
            if (res.length === 0) {
                return;
            }
            this.$bvModal.msgBoxConfirm(
                'Сохранить введенные данные?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.saveVariant(res);
                    } else {
                        this.editing = false;
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка сохранения', error.toString());
                });
        }, // срабатывает запрос на сохранения данных, при потере фокуса области ввода

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        inputFixed(item, field, value, digit) {
            const nonBreakingSpace = String.fromCharCode(160);
            const valueAsString = value.toString();
            let formatedValue = valueAsString.replaceAll(nonBreakingSpace, '');
            formatedValue = formatedValue.replaceAll(',', '.');
            formatedValue =  parseFloat(formatedValue);
            if (isNaN(formatedValue)) {
                formatedValue = 0;
            }

            const roundedValue = new Decimal(formatedValue).toDecimalPlaces(digit).toNumber();
            this.$set(item, field, roundedValue);
            this.editing = true;
        }, // форматирует введенное значение до digit цифр после запятой

        isStr(value) {
            return ((value === null) || (value === 'None') ? '' : value);
        },

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const charCode = event.keyCode;
            const key = String.fromCharCode(charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            } else {
                return true;
            }
        }, // вводит по заданному паттерну

        async loadDictTree() {
            if (!this.repYear || !this.region || !this.variant) {
                return;
            }
            try {
                const response = await fetch('/api-py/income-form/' + this.repYear + '/' + this.region + '/' + this.variant);
                const items = await response.json();
                this.dictTree = items.tree;
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDictTree', error.toString());
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            })
        }, // сообщение

        newData(kat, cls, pcl, spf, plan, note, year, region, variant, user_name, cur_year) {
            return {
                kat: kat,
                cls: cls,
                pcl: pcl,
                spf: spf,
                amount: plan,
                note: note,
                year: year,
                region: region,
                variant: variant,
                user_name: user_name,
                cur_year: cur_year,
                data_type: this.variantObjDataType
            }
        }, // формирует элемент для сохранения в БД

        openAll(forceOpen) {
            if (forceOpen) {
                if (forceOpen === 'open') {
                    this.open = true;
                } else {
                    this.open = false;
                }
            } else {
                this.open = !this.open;
            }
            for (const row of this.budgetForm) {
                row.visible = this.open;
                if ([1, 2, 3, 4].includes(row.type)) {
                    row.open = this.open;
                }
                if ([0, 1].includes(row.type)) {
                    row.visible = true;
                }
            }
        }, // открывает.закрывает все ветки

        openChilds(parent, bool) {
            parent.open = (bool === undefined ? !parent.open : bool);

            for (const row of this.budgetForm) {
                if (parent.id === row.parent_id) {
                    if ([1, 2, 3].includes(row.type)) {
                        this.openChilds(row, parent.open);
                    }
                    row.visible = parent.open;
                }
            }
        }, // открывает/закрывает ветку до конечного элемента

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        async prepareForm() {
            await this.budgetForm.splice(0);

            for (const item of this.dictTree) {
                await this.createTable(item.value, 0);
            }
            this.getMaxPlanDate();
            this.openAll();
        }, // подготовка отображения данных

        getMaxPlanDate() {
            if (this.budgetForm.length > 0) {
                const maxDate = this.budgetForm.filter(item => item.type === 5)
                    .sort((a, b) => {
                        const aVal = a.revised_plan_date ? Date.parse(a.revised_plan_date) : 0;
                        const bVal = b.revised_plan_date ? Date.parse(b.revised_plan_date) : 0;
                        return bVal - aVal
                    })[0].revised_plan_date
                if (maxDate) {
                    const newMaxDate = new Date(maxDate)
                    newMaxDate.setDate(newMaxDate.getDate() + 1);
                    this.revisedPlanMaxDat = this.addZero(newMaxDate.getDate()) + '.' + this.addZero(newMaxDate.getMonth() + 1) + '.' + newMaxDate.getFullYear()
                }
            }
        },

        addZero(numb) {
            if (numb < 10) {
                return '0' + numb
            }
            return numb;
        },

        async rememberDatas() { // подтягиваем сохраненные данные
            const that = this;

            that.valMap.clear();
            for (const spf of that.spfMap.values()) {
                that.valMap.set(that.getRowKey(spf, ['kat', 'cls', 'pcl', 'spf']), JSON.stringify(spf));
            }
        }, // расставляет сохранненные данные по полям

        reSum(parent, field) {
            let sum = 0;

            this.budgetForm.forEach(row => {
                if ((row.parent_id === parent.id) &&
                    (row.type !== parent.type)) {
                    sum += parseFloat(row[field]);
                }
            })
            return parseFloat(sum.toFixed(2));
        }, // пересчет суммы

        rowClass(item, type) {
            if (!item || type !== 'row') { return; }
            if (!item.visible) { return 'is-hidden'; }
        }, // задает класс 'is-hidden' заданной строке

        openCorrect() {
            let url_name = ""
            if (this.variantObjDataType) {
                if (this.variantObjDataType === 4) {
                    url_name = "correct"
                    window.open("/#/budget-" + url_name + "?tab=2&version=" + this.variantObj.variant_uuid, "_blank");
                } else if (this.variantObjDataType === 3) {
                    url_name = "clarify"
                    window.open("/#/budget-" + url_name + "?tab=2&version=" + this.variantObj.variant_uuid, "_blank");
                }
            }
        },

        saveDatas() {
            const res = this.compareDatas();
            if (res.length > 0) {
                this.$bvModal.msgBoxConfirm(
                    'Вы действительно хотите сохранить данные?',
                    {
                        title: 'Подтверждение',
                        size: 'lg',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        okTitle: 'ДА',
                        cancelTitle: 'Отмена',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(value => {
                        if (value) {
                            this.saveVariant(res);
                            this.editing = false;
                        } else {
                            this.editing = false;
                        }
                    })
                    .catch(error => {
                        this.makeToast('danger', 'Ошибка сохранения', error.toString());
                    })
            }
        }, // вызывает сохранение записей

        async saveVariant(items) {
            this.dsblSaveBtn = true;
            try {
                const response = await fetch('/api-py/save-income-budget', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(items)
                });
                const result = await response.json();

                if ((response.status === 200) && (result.result === 'success')) {
                    await this.rememberDatas();
                    this.makeToast('success', 'Сообщение', 'Данные сохранены');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка сохранения данных');
            }
            this.dsblSaveBtn = false;
        }, // сохранение данных в БД

        toNum(value, fix = 1) {
            if (typeof (value) === 'string') {
                return parseFloat(parseFloat(value).toFixed(fix));
            }
            if (typeof (value) === 'number') {
                return parseFloat(value.toFixed(fix));
            }
            if (typeof (value) === 'boolean') {
                return (value === true ? 1 : 0);
            }
            if (typeof (value) === 'undefined' || isNaN(value) || isFinite(value) || (value === null)) {
                return 0;
            }
        },

        total(field, digits = 2) {
            let sum = 0;
            for (const row of this.budgetForm) {
                if (row.type === 1) {
                    sum += parseFloat(row[field]);
                }
            }
            return parseFloat(sum.toFixed(digits));
        }, // итого по заданному полю

        async updateDatas() {
            this.variant = this.variantObj.variant_uuid
            this.open = true;
            // await this.budgetForm.splice(0);
            if (this.variant) {
                this.isApproved = this.listOfVariants.filter(v => v.variant_uuid === this.variant)[0].status;
            }
            // подтягиваем сохраненные данные
            await this.clearDatas();
            await this.spfMap.clear();
            this.bar = 30;
            try {
                const response = await fetch('/api-py/income-form/' + this.repYear + '/' + this.region + '/' + this.variant);
                const items = await response.json();
                this.dictTree = items.tree;
                const values = new Map(Object.entries(items.val_map));
                for (const row of values.values()) {
                    const spf = this.spfMap.get(this.getRowKey(row, ['kat', 'cls', 'pcl', 'spf']));
                    if (spf !== undefined) {
                        spf.receipt_prev = row.receipt_prev;
                        spf.receipt_curr = row.receipt_curr;
                        spf.plan = row.plan;
                        spf.note = row.note;

                        spf.plan1 = row.plan1;
                        spf.plan2 = row.plan2;
                        spf.plan3 = row.plan3;
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса updateDatas', error.toString());
            }
            this.bar = 70;
            await this.prepareForm();
            this.bar = 100;
            await this.rememberDatas();
        }, // расставляет сохранненные данные по полям

        getPeriodLst() {
            const startDate = CUR_YEAR - 2;
            const endDate = CUR_YEAR + 2;
            for (let i = endDate; i >= startDate; i--) {
                const currPeriod = { name: `${i} - ${i + 2}`, year: i - 1 }
                this.periodLst.push(currPeriod);
                if (this.mode === "report") {
                    if (this.year === i) {
                        this.curPeriod = currPeriod;
                    }
                } else {
                    if (this.year + 1 === i) {
                        this.curPeriod = currPeriod;
                    }
                }
            }
        }, // готовит список периодов для фильтра
        //=========== фильтрация данных в столбцах ===============

        filterFunction(row, val) {
            const { kat, cls, pcl, spf, name_ru } = val;
            const isOpen = [
                !kat || Number(kat) === row.kat,
                !cls || row.cls.indexOf(cls) !== -1,
                !pcl || Number(pcl) === row.pcl,
                !spf || row.spf.indexOf(spf) !== -1,
                !name_ru || row.name_ru.toLowerCase().indexOf(name_ru.toLowerCase()) !== -1
            ].every(Boolean);
            if ((kat || cls || pcl || spf || name_ru) && !this.filtered) {
                this.filtered = true;
                this.openAll('open');
            }
            if (!(kat || cls || pcl || spf || name_ru) && this.filtered) {
                this.filtered = false;
                this.openAll('close');
            }
            return isOpen;
        },
        chgData() {
            if (this.variantObj !== null) {
                const data = {
                    year: this.curPeriod.year + 1,
                    region: this.regObj.code,
                    variant: this.variantObj.variant_uuid
                };
                this.$emit('chgData', data);
            }
        },

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        checkIfCanBeEdit(item) {
            const levelAccessToEdit = [2, 3];
            return item.type === 5 && item.kat > 3 && this.mode !== 'report' && this.isVariantActive && levelAccessToEdit.includes(this.accessLevel);
        }
    }
};
</script>

<style>
    .filtered {
        color: #95B8CD;
        display: inline-block;
        margin-right: 5px;
    }
</style>